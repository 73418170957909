"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

!function (e) {
  var o = {};

  function i(a) {
    if (o[a]) return o[a].exports;
    var n = o[a] = {
      i: a,
      l: !1,
      exports: {}
    };
    return e[a].call(n.exports, n, n.exports, i), n.l = !0, n.exports;
  }

  i.m = e, i.c = o, i.d = function (e, o, a) {
    i.o(e, o) || Object.defineProperty(e, o, {
      enumerable: !0,
      get: a
    });
  }, i.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, i.t = function (e, o) {
    if (1 & o && (e = i(e)), 8 & o) return e;
    if (4 & o && "object" == _typeof(e) && e && e.__esModule) return e;
    var a = Object.create(null);
    if (i.r(a), Object.defineProperty(a, "default", {
      enumerable: !0,
      value: e
    }), 2 & o && "string" != typeof e) for (var n in e) {
      i.d(a, n, function (o) {
        return e[o];
      }.bind(null, n));
    }
    return a;
  }, i.n = function (e) {
    var o = e && e.__esModule ? function () {
      return e["default"];
    } : function () {
      return e;
    };
    return i.d(o, "a", o), o;
  }, i.o = function (e, o) {
    return Object.prototype.hasOwnProperty.call(e, o);
  }, i.p = "", i(i.s = 1);
}([function (e, o) {
  !function (e) {
    var o,
        i,
        a = [];
    a.en = {
      message: "We use cookies to provide our services. By using this website, you agree to this.",
      acceptText: "I agree",
      infoText: "Privacy policy",
      privacyText: "Privacy protection"
    };
    var n = {
      init: function init(a) {
        o = "#cookie-bar";
        var r = {
          infoLink: "https://www.google.com/policies/technologies/cookies/",
          infoTarget: "_blank",
          wrapper: "body",
          expireDays: 365,
          style: "top",
          language: e("html").attr("lang") || "en",
          privacy: !1,
          privacyTarget: "_blank",
          privacyContent: "sdfsdfsdfsd"
        };
        null != a.localization && n.addTranslation(a.localization, "custom"), i = e.extend(r, a), "accepted" !== n.getCookie("cookies-state") && n.displayBar(), e(document).on("click", o + " .cookie-bar__btn", function (e) {
          e.preventDefault(), n.setCookie("cookies-state", "accepted", i.expireDays), n.hideBar();
        }), e(document).on("click", '[data-toggle="cookieBarPrivacyPopup"]', function (e) {
          e.preventDefault(), n.showPopup();
        }), e(document).on("click", ".cookie-bar-privacy-popup, .cookie-bar-privacy-popup__dialog__close", function (e) {
          n.hidePopup();
        }), e(document).on("click", ".cookie-bar-privacy-popup__dialog", function (e) {
          e.stopPropagation();
        });
      },
      displayBar: function displayBar() {
        var o = '<button type="button" class="cookie-bar__btn">' + a[i.language].acceptText + "</button>",
            r = '<a href="' + i.infoLink + '" target="' + i.infoTarget + '" class="cookie-bar__link cookie-bar__link--cookies-info">' + a[i.language].infoText + "</a>",
            t = "";
        i.privacy && ("link" === i.privacy ? t = '<a href="' + i.privacyContent + '" target="' + i.privacyTarget + '" class="cookie-bar__link cookie-bar__link--privacy-info">' + a[i.language].privacyText + "</a>" : "bs_modal" === i.privacy ? t = '<a href="' + i.privacyContent + '" data-toggle="modal" class="cookie-bar__link cookie-bar__link--privacy-info">' + a[i.language].privacyText + "</a>" : "popup" === i.privacy && (n.renderPopup(), t = '<a href="#" data-toggle="cookieBarPrivacyPopup" class="cookie-bar__link cookie-bar__link--privacy-info">' + a[i.language].privacyText + "</a>"));
        var c = '<div id="cookie-bar" class="cookie-bar cookie-bar--' + i.style + '"><div class="cookie-bar__inner"><span class="cookie-bar__message">' + a[i.language].message + '</span><span class="cookie-bar__buttons">' + o + r + t + '</span><div class="cookie-bar__close" onclick="$(this).parent().parent().remove()"> &#10006;&#xFE0E; </div></div></div>';
        e(i.wrapper).prepend(c);
      },
      hideBar: function hideBar() {
        e(o).slideUp();
      },
      renderPopup: function renderPopup() {
        var o = e('<div id="cookieBarPrivacyPopup" class="cookie-bar-privacy-popup cookie-bar-privacy-popup--hidden"><div class="cookie-bar-privacy-popup__dialog"><button type="button" class="cookie-bar-privacy-popup__dialog__close"></button></div></div>');
        e("body").append(o), e(".cookie-bar-privacy-popup__dialog", o).append(i.privacyContent);
      },
      showPopup: function showPopup() {
        e("#cookieBarPrivacyPopup").removeClass("cookie-bar-privacy-popup--hidden");
      },
      hidePopup: function hidePopup() {
        e("#cookieBarPrivacyPopup").addClass("cookie-bar-privacy-popup--hidden");
      },
      addTranslation: function addTranslation(e) {
        var o = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "custom";
        a[o] = e;
      },
      setCookie: function setCookie(e, o, i) {
        var a = new Date();
        a.setTime(a.getTime() + 24 * i * 60 * 60 * 1e3);
        var n = "expires=" + a.toUTCString();
        document.cookie = e + "=" + o + ";" + n + ";path=/";
      },
      getCookie: function getCookie(e) {
        for (var o = e + "=", i = document.cookie.split(";"), a = 0; a < i.length; a++) {
          for (var n = i[a]; " " === n.charAt(0);) {
            n = n.substring(1);
          }

          if (0 === n.indexOf(o)) return n.substring(o.length, n.length);
        }

        return "";
      }
    };

    e.cookieBar = function (o) {
      return n[o] ? n[o].apply(this, Array.prototype.slice.call(arguments, 1)) : "object" != _typeof(o) && o ? void e.error("Method " + o + " does not exist on Cookie Bar component") : n.init.apply(this, arguments);
    };
  }(jQuery);
}, function (e, o, i) {
  i(0);
  var a = i(2);
  if (window.cookieBar = {}, "undefined" == typeof jQuery) throw new Error("jquery not defined!");
  $(document).ready(function () {
    var e = {
      infoLink: "https://finflowsoft.com/privacy-policy",
      infoTarget: "_blank",
      language: "en",
      expireDays: 365,
      style: "top"
    };
    window.cookieBar.getVersion = function () {
      console.log(a.version);
    }, window.cookieBar["default"] = function () {
      $(".cookie-bar__inner").length > 0 ? console.error("cookie bar is already initialized") : $.cookieBar({
        infoLink: e.infoLink,
        infoTarget: e.infoTarget,
        language: e.language,
        expireDays: e.expireDays,
        style: e.style
      });
    }, window.cookieBar.custom = function (o) {
      if ($(".cookie-bar__inner").length > 0) console.error("cookie bar is already initialized");else {
        var _i = {
          infoLink: o.infoLink ? o.infoLink : e.infoLink,
          infoTarget: o.infoTarget ? o.infoTarget : e.infoTarget,
          language: o.language ? o.language : e.language,
          expireDays: o.expireDays ? o.expireDays : e.expireDays,
          style: e.style,
          localization: o.localization ? o.localization : e.localization
        };
        $.cookieBar({
          infoLink: _i.infoLink,
          infoTarget: _i.infoTarget,
          language: _i.language,
          expireDays: _i.expireDays,
          style: _i.style,
          localization: _i.localization
        });
      }
    };
  });
}, function (e) {
  e.exports = {
    name: "finflow-gpdr",
    version: "1.0.2",
    main: "main.js",
    scripts: {
      "start-dev-server": "webpack-dev-server --open",
      build: "webpack -p --display-error-details",
      watch: "webpack --display-error-details --watch",
      start: 'concurrently "npm run watch" "npm run start-dev-server"'
    },
    license: "MIT",
    devDependencies: {
      concurrently: "^5.3.0",
      webpack: "^4.15.1",
      "webpack-cli": "^3.0.8",
      "webpack-dev-server": "^3.1.4"
    }
  };
}]);