$.removeSticky = function () {
    setTimeout(function () {
        $("#layout-navigation").removeClass("sticky");
    }, 100);
};

$.goTo = function (nodename) {

    var current = $(nodename);
    var scrollToElement = function (el, ms) {
        var speed = (ms) ? ms : 600;
        $('html,body').animate({
            scrollTop: $(el).offset().top
        }, speed);
    }


    if (current) {

        try {
            scrollToElement(nodename, 500);
        } catch (e) {
            console.error("slide error", e);
        }

    } else {
        console.error("can't slide");
    }


}

document.addEventListener('DOMContentLoaded', function () {
    let button = document.getElementById("QuestionFormSubmit");
    if(button != null) {
        button.addEventListener("click", function saveData() {

            let captcha = grecaptcha.getResponse();

            if (captcha.length == 0) {
                sessionStorage.setItem("inputName", document.getElementById("inputName").value);
                sessionStorage.setItem("inputEmail", document.getElementById("inputEmail").value);
                sessionStorage.setItem("inputMessage", document.getElementById("inputMessage").value);
            } else {
                sessionStorage.removeItem("inputName");
                sessionStorage.removeItem("inputEmail");
                sessionStorage.removeItem("inputMessage");
            }
        });

        document.getElementById("inputEmail").value = sessionStorage.getItem('inputEmail');
        document.getElementById("inputName").value = sessionStorage.getItem('inputName');
        document.getElementById("inputMessage").value = sessionStorage.getItem('inputMessage');
    }

}, {
    passive: true
});

$(document).ready(function () {

    /* jQuery scroll to top plugin start */

    $.topbutton({
        htlm: "⇈", //String
        css: "", //String
        scrollAndShow: false, //Boolean
        scrollSpeed: 150, //Number
        scrollAndShow: true
    });

    /* qWuery scrollToTop plugin end */

    /* call for gdpr script start */

    let privacyUrl;

    if (document.querySelector(".js-anchor-privacy-policy") != null) {
        privacyUrl = document.querySelector(".js-anchor-privacy-policy").getAttribute("href");
    } else {privacyUrl = '#';}

    let privacyPolicyURI = `${window.ff.baseURL}${privacyUrl.replace('/','')}`;
 
    let url = `${window.ff.baseURL}api/FF.Localization/localization/get?module=FF.OrchardTheme.CookieBar&language=${ff.localization}`;

    fetch(url)
        .then(response => response.json())
        .then(
              (data) => {
                const transfer = {};
                data.model.forEach(r => {
                    transfer[r.k] = r.v
                });
            
                const remappedVocabulary = {
                    message: transfer['cookiebar.label.message'], 
                    acceptText: transfer['cookiebar.label.accept-text'],
                    infoText: transfer['cookiebar.label.info-text'],
                    privacyText: transfer['cookiebar.label.privacy-text'], 
                }
                 
                cookieBar.custom({
                    infoLink: privacyPolicyURI, //link to "Show more" button
                    infoTarget: "_blank", // how to open link: infoTarget: '_blank' will open license i nnew tab
                    language: "custom", // language. Available: "ru", "en", "cz"
                    expireDays: "10", // days, when cookie will expire
                    style: "gdpr-plugin", // top
                    localization: remappedVocabulary
                });
        })
        .catch(function(error) {
            cookieBar.default();
            console.error('Request failed', error);
        }); 

    window.onscroll = function () {
        stickyNavbar()
    };
    window.onload = function () {
        stickyNavbar()
    };

    var navbar = document.querySelector("#layout-navigation nav"); // Get the navbar
    var sticky = navbar.offsetTop; // Get the offset position of the navbar


    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function stickyNavbar() {
        if (window.pageYOffset > sticky) {
            document.querySelector("#layout-navigation").classList.add("sticky");
            document.querySelector(".zone.zone-navigation").classList.add("bg-light");
        } else {
            document.querySelector(".zone.zone-navigation").classList.remove("bg-light");
            document.querySelector("#layout-navigation").classList.remove("sticky");
        }
    }

    /* Contact US Submitting form ( Do you have questions about Kaymaks? )*/
    function toggleCheck() {
        $("#QuestionFormSubmit").prop('disabled', function (i, v) {
            return !v;
        });
    };
    $('#QuestionFormSubmitCheckbox').change(function () {
        toggleCheck();
    });

    /* Used on "How it works" page with pill tab */
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        $(e.target.parentNode.parentNode).find("li").removeClass("active");
        $(e.target.parentNode).addClass("active");
    })

    $('a[data-toggle="tab"]').on('hide.bs.tab', function (e) {
        $(e.target.parentNode).removeClass("active");
    })

    document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()));

    if ($(window).scrollTop() >= 1) // Проверка состояния прокрутки
    {
        $("html, body").animate({
            scrollTop: 0
        }, "slow");
    }

    $("#modalInformation").modal('show');
});
